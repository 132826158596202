<template>
    <div>
        <v-overlay :value="overlay" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-data-table
            :headers="headers"
            :items="dataTable"
            :disable-pagination=true
            :fixed-header=true
            :loading=loadingTable
            :disable-sort="$vuetify.breakpoint.name == 'xs'"
            :key="`list-suppliers-${tenantId}`"
            :options.sync="options"
            hide-default-footer
            loading-text="Cargando prescriptores..."
            no-results-text="No se han encontrado prescriptores"
            no-data-text="Sin prescriptores"
            class="elevation-1"
            dense
        >
            <template v-slot:top>
                <ExpandableFilters 
                    title="PRESCRIPTORES"
                    :filters=filters
                    titleButton='Nuevo'
                    iconToolbar='mdi-account-star'
                    classButton='py-0 py-md-2 text-center mb-md-0 col-6 col-sm-2 px-md-0'
                    :actionButton="editarPrescriptor"
                    @datafilters=getPrescriptores
                />
            </template>
            <template v-slot:item.type="{ item }">
                {{item.type.split('_').join(' ').toUpperCase()}}
            </template>
            <template v-slot:footer>
                <v-pagination
                    class='mt-10'
                    v-model=currentPage
                    :length=totalItems
                    @input="handlePageChange"
                    total-visible="15"
                ></v-pagination>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip 
                    bottom
                    v-if="item.status_id != 'PARTY_TO_VALIDATE'"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn  
                            v-bind="attrs"
                            v-on="on" 
                            icon 
                            @click="editarPrescriptor(item.party_id)"
                        >
                            <v-icon color='orange'>mdi-account-edit</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar</span>
                </v-tooltip>
                <v-tooltip 
                    bottom 
                    v-if="item.status_id=='PARTY_IN_PROCESS'"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn  
                            v-bind="attrs"
                            v-on="on" 
                            icon 
                            @click="sendApproval(item)"
                        >
                            <v-icon color='success'>mdi-send</v-icon>
                        </v-btn>
                    </template>
                    <span>Enviar para aprobación</span>
                </v-tooltip>
                
            </template>
        </v-data-table>
        
        
        
     <v-dialog
      v-model="dialogPrescriptor"
      persistent
      max-width="400px"
    >
    
    <v-card>
        <v-card-title>
             <v-icon>mdi-doctor</v-icon>
          <span class="headline">Prescriptor</span>
        </v-card-title>
        
    
    
     <v-card-text>
          <v-container>
          <v-form ref="formPrescriptor">
          
            <v-row>
              
              
              
              <v-col cols="12" class="pt-0 pb-0">
                <v-text-field
                  v-model="nombre_presc"
                  label="Nombres*"
                  :rules=required
                  type="text"
                  
                  small
                ></v-text-field>
              </v-col>
              
               <v-col cols="12" class="pt-0 pb-0">
                <v-text-field
                  v-model="apellido_presc"
                  label="Apellido*"
                  
                  type="text"
                  :rules=required
                  small
                  
                ></v-text-field>
              </v-col>
              
              <v-col class="pt-0 pb-0"
                cols="12"
                sm="10"
                md="10"
              >
              
                
                <v-select
               	  v-model="especialidad_presc"
                  small
                  item-text="esp_nombre"
          		  item-value="esp_id"
                  :items="especialidades"
                  label="Especialidad"
                  :rules=required
                  
                ></v-select>
                
              </v-col>
              
              <v-col cols="12"  sm="8" md="8" class="pt-0 pb-0">
                <v-text-field
                  v-model="cedula_presc"
                  label="Cédula"
                  type="text"
                  
                  
                  small
                  
                  
                ></v-text-field>
              </v-col>
              
               
              
              
            </v-row>
            </v-form>
          </v-container>
          <small>*campos obligatorios</small>
        </v-card-text>
       
        
        <v-card-actions class="mb-2">
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            small
            @click="dialogPrescriptor = false"
          > <v-icon>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-btn
            :loading= bl
            :disabled= bl
            color="blue darken-1"
            solid
            small
            class = "white--text"
            @click="guardarPrescriptor()"
          >
          <v-icon>mdi-content-save</v-icon>
            Guardar
          </v-btn>
          
          
          
        </v-card-actions>
     </v-card>
    </v-dialog>
        
        
    </div>
</template>

<script>

import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'
import Vue from 'vue'
import ExpandableFilters from '../general/ExpandableFilters'
import PartyComponent from '../general/PartyComponent'

export default {
    name:'PrescriptoresComponent',
    components:{
        ExpandableFilters,
        PartyComponent
    },
    data:()=>({
        dataTable:[],
        setfilters:[],
        dialog:false,
        overlay:false,
        search:'',
        totalItems:1,
        currentPage:1,
        itemsPerPage:20,
        dataParty:{},
        
        options: {},
        
        id_resc: "",
        nombre_presc: "",
        apellido_presc: "",
        cedula_presc: "",
        especialidad_presc: "",
        
        dialogPrescriptor: false,
        bl:false,

        headers:[
            {text: 'Código', align: 'start', value: 'party_id'},
            {text: 'Nombre', align: 'start', value: 'nombres'},
            {text: 'Identificación', align: 'start', value: 'identificacion'},            
            {text: 'Especialidad', align: 'start', value: 'especialidad'},
            {text: 'Acciones', align: 'start', value: 'actions',align:'center'}
        ],
        especialidades: [],
        filters:[
            {
                cols:6,
                class:'py-0 py-md-2 col-md-1 pl-0 pl-sm-2',
                v_model:'',
                label: 'Código',
                type:'input_text'
            },
            
            {
                cols:6,
                class:'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
                v_model:'',
                label: 'Nombres',
                type:'input_text'
            },
            {
                cols:6,
                class:'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
                v_model:'',
                label: 'Apellidos',
                type:'input_text'
            },
            {
                cols:6,
                class:'py-0 py-md-2 col-md-1 pl-0 pl-sm-2',
                v_model:'',
                clearable:true,
                label: 'Especialidad',
                type:'input_select',
                text:'esp_nombre',
                value:'esp_id',
                items: [],

            }
        ],
       
        
        required:[v => !!v || 'El campo es requerido'],
        emailValid:[
            v => !!v || 'Debe escribir un correo electrónico',
            v => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Debe escribir un correo válido'
        ]
    }),
    watch:{
        options: {
            handler () {
                this.getPrescriptores(this.filters)
            },
            deep: true,
        },
    },
    computed:{
        
        ...mapState('pos',['menuPos']),

        ...mapState('master',['loadingTable','tenantId','user']),

         

        url(){
            return this.$route.fullPath.split('/')[1]
        }

    },
    methods:{

        ...mapMutations('master',['setMenu','setUrl','setLoadingTable','setTitleToolbar']),

        ...mapActions('master',['requestApi','alertNotification']),

        handlePageChange(value) {
            this.currentPage = value
            this.getPrescriptores(this.filters)
        },

        getPrescriptores(filters) {
			
            this.setLoadingTable(true)
            this.setfilters = filters
            this.dataTable=[]
            
            this.setfilters.forEach(obj=>{
               if(this.currentPage!=1  && obj.v_model!=''){
                   this.currentPage=1
                   return false;
               }
            })

            this.setUrl('lista-partys')
            this.requestApi({
				method: 'GET',
				data :{
                    page:this.currentPage,
                    itemsPerPage: this.itemsPerPage,
                    code: this.setfilters[0].v_model,
                    first_name:this.setfilters[1].v_model,
                    last_name:this.setfilters[2].v_model,                    
                    espec: this.setfilters[3].v_model,
                    typeList: 'prescriptores',
                    paginator: true
                }
			}).then(res =>{
                console.log(res)
                this.totalItems = res.data.page_count
                this.dataTable = res.data._embedded.lista_partys
			}).then(()=>{
                this.setLoadingTable(false)
            })
        },
        
		getEspecialidades() {
			
            this.setLoadingTable(true)

            this.setUrl('especialidad')
            
            this.requestApi({
				method: 'GET',
				data :{                    
                }
			}).then(res =>{
                console.log(res)
                //this.totalItems = res.data.page_count
                this.especialidades = res.data._embedded.especialidad
                this.filters[3].items = res.data._embedded.especialidad
			}).then(()=>{
                this.setLoadingTable(false)
            })
        },
		cargarPrescriptor(party_id) {
            
            this.nombre_presc = ""
        	this.apellido_presc = ""
        	this.cedula_presc = ""
        	this.especialidad_presc = ""
	        this.id_presc = ""
	            
        	this.setUrl('prescriptor/'+party_id)
        	this.requestApi({
	            method: 'GET',
	            data :{                
	            	
	            }
        	}).then(res =>{
            
	            //console.log(res)
	            //this.dataParty = res.data
	            //this.availableItems = noPartyId ? { generalData:true } : this.defaultAvailableItems
	            this.nombre_presc = res.data.first_name
        		this.apellido_presc = res.data.last_name
        		this.cedula_presc = res.data.cedula
        		this.especialidad_presc = res.data.especialidad_id
	            this.id_presc = res.data.party_id
	            
	            this.dialog=true
				
	        }).catch(()=>{
	            
	        }).then(()=>{	
	            this.setLoadingTable(false)
	            this.overlay=false
	            
	        });
        },
        
        editarPrescriptor(party_id){
            //console.log(this.url)
            let crearPrescriptor  = typeof party_id =='object'
            //console.log(party_id);
            //console.log(crearPrescriptor)
            this.overlay=true
            this.bl = false
            if(!crearPrescriptor) {
            	            
            	this.cargarPrescriptor(party_id)
            } else {
            	this.nombre_presc = null
            	this.apellido_presc = null
            	this.especialidad_presc = null
            	this.id_presc = null;
            }
        	this.dialogPrescriptor = true;    
           
            this.overlay=false       
        },

        guardarPrescriptor() {
			if( !this.$refs.formPrescriptor.validate() ) {
        		
        		return false
        	}
        	
        	this.bl = true

        	if(this.id_presc == null) {
        		this.setUrl('prescriptor')
        		this.requestApi({
    	            method: 'POST',
    	            data :{                
    	            	'first_name' : this.nombre_presc,
    	            	'last_name' : this.apellido_presc,
    	            	'especialidad_id' : this.especialidad_presc,
    	            	'cedula' : this.cedula_presc
    	            }
            	}).then(res =>{
    	            
    	            this.dialogPrescriptor=false
                    this.bl=false
                     this.alertNotification({
                            param:{
                                html: 'El prescriptor ha sido guardado'
                            }
                        })

    	        }).catch(()=>{
    	            
    	        }).then(()=>{	
    	            this.setLoadingTable(false)
    	            this.overlay=false
    	            this.bl = false;
    	        });	
        	} else {
        		this.setUrl('prescriptor/'+this.id_presc)
        		this.requestApi({
    	            method: 'PATCH',
    	            data :{                
    	            	
    	            	'party_id' : this.id_presc,
    	            	'first_name' : this.nombre_presc,
    	            	'last_name' : this.apellido_presc,
    	            	'especialidad_id' : this.especialidad_presc,
    	            	'cedula' : this.cedula_presc
    	            }
            	}).then(res =>{
                
                    this.alertNotification({
                            param:{
                                html: 'El prescriptor ha sido guardado'
                            }
                        })
    	            this.bl=false
    	            this.dialogPrescriptor=false
                    

    	        }).catch(()=>{
    	            
    	        }).then(()=>{	
    	            this.setLoadingTable(false)
    	            this.overlay=false
                    this.bl=false
    	            
    	        });
        	}
        	
        	
        	
            this.getPrescriptores(this.filters)
        },

        
    },
    mounted(){
    	this.setTitleToolbar('PRESCRIPTORES')
    	
    	this.getEspecialidades()
    	
    	
       /* if(typeof this.$route.params.partyId != 'undefined' && typeof this.$route.params.workEffortId != 'undefined'){
            this.filters[0].v_model = this.$route.params.partyId
            this.filters[4].v_model = 'PARTY_IN_PROCESS'
        }

        typeof this.$route.params.partyId != 'undefined' && 
            this.editSupplier(this.$route.params.partyId)*/
    }

}
</script>

<style>
    .v-dialog{
        background-color: white;
    }
    .v-divider--inset{
        max-width: 100%!important;
        margin-left:10px!important;
        border-color: rgb(212 212 212)!important;
    }
</style>